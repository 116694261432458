import React from "react";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
import flatten from "flat";
import enContent from "../../content/apply/en.json";
import enCommonContent from "../../content/common/en.json";
import theme from "../../theme";
import Locale from "../../types/Locale";
import GlobalHead from "../../components/GlobalHead";
import GlobalStyle from "../../components/GlobalStyle";
import Apply from "../../components/Apply";
import ImagesContext from "../../helpers/ImagesContext";
import useImages from "../../helpers/useImages";

const content = flatten({ ...enContent, common: { ...enCommonContent } });

const ApplyPage: React.FC = () => {
  const images = useImages();

  return (
    <IntlProvider
      defaultLocale={Locale.en}
      locale={Locale.en}
      messages={content}
    >
      <ThemeProvider theme={theme}>
        <ImagesContext.Provider value={images}>
          <GlobalHead locale={Locale.en} />
          <GlobalStyle />
          <Apply />
        </ImagesContext.Provider>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default ApplyPage;
